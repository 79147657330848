import React, { useContext } from "react";

import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { Search } from "react-feather";

import { OrderContext } from "../../context/orders-context";
import { sendNotification } from "../../helpers/notification";

const FindByDate = (props) => {

  registerLocale('ru', ru)
  const [loading, setLoading] = React.useState(false);
  const orderCunsumer = useContext(OrderContext);

  // const updateField = async (event) => {
  //   await orderCunsumer.updateState(event.target.name, event.target.value);
  // };

  const submit = async () => {
    setLoading(true)
    return orderCunsumer
      .getExportByDate()
      .then(() => sendNotification("success", "Успех", "Запрос отправлен."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label style={{ fontSize: 16, color:'#fff' }} htmlFor="comment">
            <strong>Выберите дату и время:</strong>
          </label>
          <DatePicker 
            selected={orderCunsumer.dateToSearch}
            placeholder="Выберите дату"
            wrapperClassName="form-control"
            className="form-control"
            onChange={(date) => orderCunsumer.updateState('dateToSearch', date)}
            showTimeSelect
            locale="ru"
          />
          <p className="card-description mt-2" style={{ fontSize: 14, color: '#7886a1'}}>Дата и время, начиная с которого мы соберем заявки</p>
        </div>
        {loading === true ? (
          <button className="btn btn-line-info btn-small mt-4">
            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            Загрузка...
          </button>  
        ) : (
          <button
            onClick={(e) => submit(e)}
            className={`btn btn-line-info btn-line-info-hover btn-small mt-4`}
          >
            Получить по дате  <Search className="link-icon ms-1 mb-1" size={15} />
          </button>
        )}
      </form>
    </>
  );
};

export default FindByDate;