import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import moment from "moment";
import 'moment/locale/ru'
import { FileText, Send, AlignJustify } from "react-feather";

import { sendNotification } from "../../helpers/notification";
import FindByDate from '../../components/forms/findByDate';
import AdminBar from "../../components/AdminBar";
import Checkbox from '@material-ui/core/Checkbox';
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

const ExportedOrders = (props) => {

  moment.locale('ru');
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showtotable, setShowtotable] = React.useState(false);
  const [btnload, setBtnload] = React.useState(false);
  const OrderCunsumer = useContext(OrderContext);
  const userCunsumer = useContext(UserContext);

  useEffect(() => {
    return () => OrderCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (OrderCunsumer.orderArray && OrderCunsumer.orderArray.length > 0){
      setShowtotable(true);
    }
  }, [OrderCunsumer.orderArray]);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]); 


  let columns = [
    { name: "id",
      sortable: true,
      width: "80px",
      selector: (row) => row.id,
      cellExport: row => row.id,
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      sortable: true,
      width: "200px",
      cellExport: (row) => row.externalId,
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
    { name: "Статус",
      sortable: true,
      width: "180px",
      selector: (row) => row.status,
      cellExport: row => row.status,
      cell: (row) => row.status,
    },
    { name: "Карта",
      sortable: true,
      width: "250px",
      selector: (row) => row.card,
      cellExport: row => row.card,
      cell: (row, index) => row.card,
    },
    { name: "Банк",
      sortable: true,
      width: "120px",
      selector: (row) => row.bank,
      cellExport: (row) => row.bank,
      cell: (row) => {
        return `${row.bank}`;
      },
    },
    { name: "Cумма в RUB",
      sortable: true,
      width: "120px",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.totalUsdt,
      sortable: true,
      width: "130px",
      cellExport: (row) => row.totalUsdt,
      cell: (row) => row.totalUsdt,
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "ФИО клиента:",
      width: "200px",
      selector: (row) => row.clientName,
      cellExport: (row) => row.clientName,
    },
    { name: "Callback url",
      selector: (row) => row.callbackUrl,
      sortable: true,
      width: "400px",
      cellExport: (row) => row.callbackUrl,
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
    { name: "Дата создания",
      sortable: true,
      width: "195px",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      width: "195px",
      selector: (row) => row.successedAt,
      cellExport: (row) => row.successedAt,
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
  };

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    print: false,
    filter: false,
    export: true,
    exportHeaders: true
  };

  const handler = async (event) => {
    await OrderCunsumer.updateState(event.target.name, event.target.value);
  };

  const moveToOrders = async () => {
    await OrderCunsumer.updateState("orders", OrderCunsumer.orderArray);
  };

  const hendleSelected = async (state) => {
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
  }

  const submit = async () => {
    setBtnload(true)
    return OrderCunsumer.getExport()
      .then(() => sendNotification("success", "Успех", "Заявки на экспорт получены."))
      .then(() => setBtnload(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ", e.response.body.message);
        }
      });
  };

  return (
    <>
      {renderRedirect()}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Заявки на экспорт
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="form-group">
                            <label style={{ fontSize: 16, color: '#fff' }} htmlFor="comment">
                              <strong>Введите ID заявки от которой надо забрать новые заявки:</strong>
                            </label>
                            <input
                              type="text"
                              onChange={handler}
                              className="form-control mt-2 mb-3"
                              placeholder="3000"
                              name="exportid"
                              id="exportid"
                              style={{height: '40px'}}
                            />
                            <p className="mb-3 text-muted" style={{fontSize: '14px'}}>(Пример: если указать ID 2004, посчитает все заявки с ID больше 2004.)</p>
                          </div>
                          {btnload === true ? (
                            <button className="btn btn-line-info btn-small">
                              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                              Загрузка...
                            </button>  
                          ) : (
                            <button
                              onClick={(e) => submit(e)}
                              className={`btn btn-line-primary btn-line-primary-hover btn-small`}
                            >
                              Получить заявки  <FileText className="link-icon ms-1 mb-1" size={16} />
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                    {showtotable === true ? (
                      <div className="card mt-3">
                        <div className="card-body">
                        Поместить в таблицу только выделенные
                          <button
                            onClick={(e) => moveToOrders()}
                            className={`btn btn-line-success btn-line-success-hover btn-small ms-5`}
                            style={{fontSize: "13px",}}
                          >
                              {OrderCunsumer.orderArray.length} заявок 
                            <Send className="link-icon ms-2" style={{position: 'relative', top: '-1px'}} size={16} />
                          </button>
                        </div>
                      </div>
                    ) : ("")}
                  </div>
                  <div className="col-md-6 media-mt-3 stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <FindByDate />
                      </div>
                    </div>
                  </div>
                </div>
                {!isEmpty(OrderCunsumer.orders) &&
                  <div className="row ">
                    <div className="col-md-12 mt-4 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                              <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <DataTableExtensions {...tableData} >
                                  <DataTable
                                    title={false}
                                    columns={columns}
                                    highlightOnHover={true}
                                    striped={true}
                                    pointerOnHover={true}
                                    className={"table dataTable"}
                                    pagination={true}
                                    noHeader={true}
                                    theme="solarized"
                                    paginationPerPage={50}
                                    selectableRows={true}
                                    selectableRowsComponent={Checkbox}
                                    selectableRowsComponentProps={{ color: 'primary' }}
                                    selectableRowsVisibleOnly={true}
                                    selectableRowsHighlight={true}
                                    onSelectedRowsChange={hendleSelected}
                                    paginationRowsPerPageOptions={[
                                      50,
                                      100,
                                      200,
                                      300,
                                      400,
                                    ]}
                                  />
                                </DataTableExtensions>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportedOrders;
